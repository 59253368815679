.share-container {
	padding: 3rem 9rem;
	min-height: 100vh;
}

.share-element > h3,
h4 {
	margin-bottom: 0.5rem;
}

.share-container > select {
	width: 100%;
	max-width: 8rem;
	border: 1px solid var(--select-border);
	border-radius: 0.25em;
	padding: 0.25em 0.5em;
	margin-top: 1rem;
	cursor: pointer;
	background-color: var(--main-color-light);
}

.share-container > select:focus {
	outline: none;
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	.share-container {
		padding: 1rem;
		min-height: 100vh;
	}
}
/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
