#inspirations > div {
	margin-bottom: 1rem;
}

#inspirations > div > h2 {
	margin-bottom: 0.75rem;
	color: var(--main-color-dark);
}

/* INSPIRATION CARD */
.card-list {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background-color: transparent;
}

.card-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 20%;
}

.card-title {
	color: var(--main-color-dark);
}

.card-text {
	width: 256px;
	max-width: 256px;

	line-height: 2rem;
	font-size: 0.8rem;

	border-radius: 0.75rem;
	box-shadow: 0px 0px 15px -10px #000000;
	padding: 0.75em;
	margin-bottom: 1rem;
}

.card-img {
	width: 256px;
	height: 256px;
	max-width: 256px;
	max-height: 256px;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 0.75rem;
	box-shadow: 0px 0px 15px -10px #000000;
	padding: 0.75em;
	margin-bottom: 0.75rem;
}

.card-img-loading {
	width: 50%;
	height: 50%;
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	.card-list {
		flex-direction: column;

		margin: auto;
	}

	.card-item {
		width: 100%;
	}
}
/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
