#user-journey {
	display: flex;
	background-color: white;
}

.persona-container {
	display: flex;
	flex-direction: column;
	justify-content: center;

	background-color: #fff;
	padding: 1rem;

	width: 20%;
}

.persona-info {
	overflow: scroll;
}
.persona-info::-webkit-scrollbar {
	display: none;
}

.persona-info {
}

.persona-info::-moz-scrollbar {
	display: none;
}

.persona-title {
	font-size: 0.9rem;
	margin-bottom: 1rem;
	text-align: center;
}

.persona-touchpoint {
	font-size: 0.8rem;
	margin-bottom: 1rem;
	text-align: center;

	background-color: var(--main-color-light);
	padding: 0.25rem;
	border-radius: 0.5rem;
}

.persona-need,
.persona-insight {
	font-size: 0.85rem;
	margin-bottom: 0.5rem;
}

.graph-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 67%;
	height: 100%;
}

#graph {
}

#tooltip-container {
	height: 20%;

	background-color: #fff;
	padding: 2rem 1rem;

	overflow: auto;
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	#user-journey {
		flex-direction: column;
		gap: 1rem;
	}

	.persona-container {
		width: 100%;
	}

	.persona-container > img {
		margin: auto;
		width: 50%;
	}
}
/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
