.collapsible .header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	background-color: var(--main-color-dark);
	color: white;

	margin-top: 1rem;
	padding: 0.75rem 1rem;
	border-radius: 1rem;
	cursor: pointer;
}

.collapsible .content {
	background-color: var(--main-color-light);
	color: black;
	padding: 0.75rem 1rem;
}

.collapsible .header-expanded {
	border-radius: 1rem 1rem 0rem 0rem;
}

.collapsible .content-expanded {
	border-radius: 0rem 0rem 1rem 1rem;
}
