#feedback-container {
	position: fixed;
	bottom: 10px;
	right: 10px;

	background-color: var(--main-color-dark);
	color: white;
	padding: 0.75rem 1rem;
	border-radius: 0.75rem;
	border: 1px solid var(--main-color-dark);

	cursor: pointer;
}

#feedback-container:hover {
	background-color: var(--main-color-light);
	color: var(--main-color-dark);
}

#feedback-form input,
#feedback-form textarea {
	margin-top: 0.5rem;
	margin-bottom: 1rem;

	border-radius: 1rem;
	border: 1px solid gray;
	padding: 0.5rem;
	box-shadow: none;
}

#feedback-form input:not([type="radio"]),
#feedback-form textarea {
	width: 100%;
}

#feedback-form textarea {
	height: 8rem;
}

.radio-btns {
	text-align: start;
}

.radio-btns input[type="radio"]:not(:first-child) {
	margin-left: 2rem;
}

.radio-btns input[type="radio"] {
	margin-right: 0.35rem;
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	#feedback-container {
		display: none;
	}
}
/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
