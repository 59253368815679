/* FLIGHT CONTAINER */
.flight-container {
	display: flex;

	height: calc(100vh - 82px);
}

/* | FLIGHTS LIST */
.flight-list {
	background-color: var(--main-color-light);

	width: 22%;
	min-width: 22%;

	padding: 1rem;

	max-height: 100%;
	overflow-y: auto;
}

.flight-list-closed {
	display: none;
}

.flight-add {
	color: white;
	background-color: var(--main-color-dark);

	padding: 0.5rem 1rem;
	margin-bottom: 1rem;

	border: 1px solid var(--main-color-dark);
	border-radius: 0.25rem;

	cursor: pointer;
	transition: 0.3s;
}

.flight-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--main-color-light);

	padding: 0.5rem 1rem;
	margin: 0.5rem 0rem;

	border: 1px solid var(--main-color-dark);
	border-radius: 0.25rem;

	cursor: pointer;
	transition: 0.3s;
}

.flight-item:hover {
	background-color: var(--main-color-dark);
}

.flight-item--active {
	background-color: var(--main-color-dark);
	color: white;

	border: 1px solid var(--main-color-dark);
	border-radius: 0.25rem;

	padding: 0.5rem 1rem;

	cursor: pointer;
	transition: 0.3s;
}

.flight-add__text,
.flight-item__date {
	font-size: 0.8rem;
	margin-left: 0.75rem;
}

.flight-link {
	text-decoration: none;
	color: var(--main-color-dark);
}

.flight-link:hover {
	color: white;
}

.flight-item__rename {
	margin-right: 0.75rem;
}

.flight-item__share {
	margin-right: 0.75rem;
}

/* | FLICHT ACTIVE */
.flight-active {
	width: 78%;
}

.flight-active-fullscreen {
	width: 100%;
}

.tabs-container {
	display: flex;
	gap: 1.25rem;
	padding: 1rem 1rem;
	justify-content: start;
	align-items: center;

	overflow: auto;
}

.tab {
	font-size: 0.85rem;
	text-align: center;
	padding: 0.5rem 1rem;
	border-radius: 0.25rem;
	cursor: pointer;
}

.tab--active {
	background-color: var(--main-color-dark);
	color: white;
}

.tab--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
	color: gray;
}

.pdf-icon--enabled {
	color: var(--main-color-dark);
	font-size: 2rem;
	cursor: pointer;
}

.pdf-icon--disabled {
	color: gray;
	font-size: 2rem;
	cursor: not-allowed;
	opacity: 0.35;
	pointer-events: none;
}

/* SIDEBAR CONTROL */
.chevron {
	position: relative;
	top: 50%;
	left: 10px;

	color: var(--main-color-light);
	font-size: 1.5rem;

	cursor: pointer;
	transition: 0.3s;
}

.chevron:hover {
	color: var(--main-color-dark);
}

.chevron {
	outline: none;
}

.chevron:focus {
	outline: none;
	box-shadow: none;
}

/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	.flight-container {
		display: flex;
		flex-direction: column;

		height: auto;
	}

	.flight-list {
		display: flex;

		align-items: center;
		gap: 0.25rem;

		background-color: transparent;
		width: 100%;
		min-width: 100%;

		padding: 0rem 0.25rem;

		font-size: 0.75rem;
	}

	.flight-add {
		margin-bottom: 0rem;
	}

	.flight-add__text {
		display: none;
	}

	.flight-item {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--main-color-light);

		padding: 0.25rem 0.5rem;
	}

	.flight-item__plane-icon {
		display: none;
	}

	.flight-item__rename {
		display: none;
	}

	.flight-item__share {
		display: none;
	}

	.flight-item__delete {
		display: none;
	}

	.flight-add__text,
	.flight-item__date {
		margin-left: 0rem;
	}

	.flight-active {
		width: 100%;
	}

	.tabs-container {
		display: flex;
		gap: 0.25rem;

		overflow: auto;

		padding: 0rem 0.25rem;
		align-items: center;
		justify-content: space-between;
	}

	.tab {
		text-align: center;
	}

	.chevron {
		display: none;
	}
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
}
/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
