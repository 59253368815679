@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
	--main-color-dark: #861b49;
	/* --main-color-dark: #8f3a59; */ /* older plum color - slightly lighter */
	--main-color-light: #ddc8d0;

	--success-color: #789d4a;

	--bg-color: #eeeeee;
}

body {
	font-family: "Montserrat";
	background-color: var(--bg-color);
}

.bg-linear-gradient {
	background: linear-gradient(to bottom right, rgba(162, 218, 226, 0.7), rgba(205, 152, 147, 0.7), rgba(243, 210, 120, 0.7));
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	height: 100vh;
	max-height: 100vh;
}

.text-dark {
	color: var(--main-color-dark);
}

.btn {
	border-radius: 1rem;
	padding: 0.75rem 1.5rem;

	text-decoration: none;
	cursor: pointer;
}

.btn-dark {
	border: 1px solid var(--main-color-dark);
	background-color: var(--main-color-dark);
	color: white;

	transition: 0.3s;
}

.btn-dark:hover {
	border: 1px solid var(--main-color-dark);
	background-color: var(--main-color-light);
	color: var(--main-color-dark);
}

.btn-light {
	border: 1px solid var(--main-color-light);
	background-color: var(--main-color-light);
	color: var(--main-color-dark);

	transition: 0.3s;
}

.btn-light:hover {
	border: 1px solid var(--main-color-light);
	background-color: var(--main-color-dark);
	color: var(--main-color-light);
}

.divider {
	height: 3px;
	border-radius: 1rem;
	margin: 1rem 0rem;
	background-color: var(--main-color-dark);
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

/* | INPUT FIELD */
input::-webkit-input-placeholder,
input:-ms-input-placeholder,
input:-moz-placeholder,
input::-moz-placeholder {
	font-family: "Montserrat";
}

/* | SCROLLBAR */
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-thumb {
	background: var(--main-color-light);
	border-radius: 6px;
}

/* | SLIDER */
input[type="range"] {
	-webkit-appearance: none; /* Remove default appearance */
	width: 100%;
	height: 8px; /* Height of the slider */
	background: white; /* Color of the unfilled part */
	border-radius: 5px;
	outline: none;
}

input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 16px; /* Thumb width */
	height: 16px; /* Thumb height */
	background: var(--main-color-dark);
	cursor: pointer;
	border-radius: 50%; /* Make the thumb round */
}

input[type="range"]::-moz-range-thumb {
	width: 16px;
	height: 16px;
	background: var(--main-color-dark);
	cursor: pointer;
	border-radius: 50%;
}

input[type="range"]::-ms-thumb {
	width: 16px;
	height: 16px;
	background: var(--main-color-dark);
	cursor: pointer;
	border-radius: 50%;
}

/* | SPINNER */
.loading-mask {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	z-index: 9999;
	font-size: 24px;
	color: #333;
}

.loading-text {
	color: var(--main-color-dark);
	margin-top: 1rem;
	font-size: 1.5rem;
}

/* | TOOLBAR TEXT EDITOR */

.toolbar-class {
	padding: 0.5rem;
	border-radius: 0.5rem;
	background-color: var(--main-color-light);
}

.toolbar-class .rdw-option-wrapper {
	border: none;
	padding: 0.75rem;
}

.toolbar-class .rdw-option-wrapper:hover {
	background-color: var(--main-color-dark);
}

/* ANIMATIONS */
.slide-in-from-right {
	animation: 0.75s ease-out 0s 1 slideInFromRight forwards;
	background-color: transparent !important;
}

.slide-in-from-left {
	animation: 0.75s ease-out 0s 1 slideInFromLeft forwards;
	background-color: transparent !important;
}

@keyframes slideInFromRight {
	0% {
		transform: translateX(30%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes slideInFromLeft {
	0% {
		transform: translateX(-10%);
	}
	100% {
		transform: translateX(0);
	}
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	.tab-container {
		display: flex;
		flex-direction: column;
		margin: 1rem auto;

		padding: 0.25rem 1rem;
		width: 100%;

		max-height: 75vh;

		overflow-y: auto;
	}
}

/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
