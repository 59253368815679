/* | NAVIGATION */
.nav {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	background-color: var(--main-color-dark);
	padding: 1rem 2rem;
}

.nav__link {
	color: white;
	text-decoration: none;
	font-size: 1.25rem;

	transition: 0.3s;
}

.nav__link:hover {
	color: var(--main-color-light);
}

/* | NAVIGATION LOGO */
.nav__logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	margin-right: auto;
}

.nav__img {
	width: 50px;
}

/* | NAVIGATION CONTROLS */
.nav__controls {
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 1.5rem;
}

.nav__user {
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 1.5rem;
}

.nav__user-email {
	color: white;
	font-size: 1rem;
	text-decoration: none;
	transition: 0.3s;
}

.nav__user-icon {
	color: white;
	font-size: 2.5rem;
	cursor: pointer;
	transition: 0.3s;
}

.nav__user-icon:hover {
	color: var(--main-color-light);
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	.nav__logo > .nav__link:nth-child(2) {
		display: none;
	}

	.nav__user-email {
		display: none;
	}
}

/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
