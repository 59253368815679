/* | ERROR MESSAGE CONTAINER */
.error-message {
	background-color: var(--main-color-light);
	color: var(--main-color-dark);

	text-align: center;
	font-weight: bold;

	min-width: 30%;
	padding: 0.75rem 1rem;
	border-radius: 1rem;

	position: absolute;
	right: 2%;
	top: 2%;
}

/* | SUCCESS MESSAGE CONTAINER */
.success-message {
	background-color: var(--success-color);
	color: white;

	text-align: center;
	font-weight: bold;

	min-width: 30%;
	padding: 0.75rem 1rem;
	border-radius: 1rem;

	position: absolute;
	right: 2%;
	top: 2%;
}
