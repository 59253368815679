.submit-btn {
	margin: 1rem 0rem;
	padding: 0.75rem 1rem;
	background-color: var(--main-color-dark);
	color: white;
	border: 1px solid var(--main-color-dark);
	border-radius: 1rem;

	cursor: pointer;
	transition: 0.3s;
}

#questionnaire > button:hover {
	background-color: var(--main-color-light);
	color: var(--main-color-dark);
}

.submit-btn-disabled {
	background-color: #ddd;
	color: #aaa;
	cursor: not-allowed;
}

.edit-icon {
	color: var(--main-color-dark);
	font-size: 1.25rem;
	cursor: pointer;

	transition: 0.3s;
}

.edit-icon:focus {
	outline: none;
}

.edit-icon:hover {
	color: var(--main-color-light);
	font-size: 1.25rem;
	cursor: pointer;
}

/* VERSION CONTROL */
.version-control {
	margin-right: 1rem;
	margin-left: 1rem;

	color: var(--main-color-dark);
	font-size: 1.5rem;

	cursor: pointer;
	transition: 0.3s;
}

.inactive {
	color: lightgray;
}

.version-control:hover {
	color: var(--main-color-light);
}

.version-control {
	outline: none;
}

.version-control:focus {
	outline: none;
	box-shadow: none;
}
