/* Tabs */
.tab-container {
	display: flex;
	flex-direction: column;
	margin: 0.5rem auto;

	padding-left: 5rem;
	padding-right: 5rem;
	width: 100%;

	max-height: 75vh;

	overflow-y: auto;
}

.tab-title {
	margin-bottom: 0.75rem;
	color: var(--main-color-dark);
}

.selectable-titles-container {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.selectable-title {
	width: 100%;
	margin: 1rem 0rem;
	padding: 1rem;

	border-radius: 1rem;
	background-color: var(--main-color-light);

	cursor: pointer;
	transition: 0.3s;
}

.selectable-title:hover {
	background-color: var(--main-color-dark);
	color: white;
}

.selected-selectable-title {
	background-color: var(--main-color-dark);
	color: white;
}

/* Concept Poster */
.concept-poster-img {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.75rem;
	box-shadow: 0px 0px 15px -10px #000000;
	padding: 0.75em;
	margin-bottom: 0.75rem;
}

.keyword-line {
	display: flex;
	gap: 1rem;
	align-items: center;
	margin: 0;
}

.keyword-line p {
	width: 100%;
	margin-bottom: 0.5rem;
}

.keyword-strong {
	font-weight: bold;
}
