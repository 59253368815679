/* GENERAL */
#login,
#register,
#profile {
	display: flex;
}

.text-plum {
	color: var(--main-color-dark);
}

.separator {
	width: 50px;
	height: 5px;

	margin-top: 0.5rem;
	margin-bottom: 1rem;
	border-radius: 3px;
	background-color: var(--main-color-dark);
}

.link {
	text-decoration: none;
	color: var(--main-color-dark);
}

/* LEFT SIDE */
.auth-left {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	text-align: center;

	width: 33%;
	height: 100vh;

	color: white;
	background-color: var(--main-color-dark);
}

.auth-left > a {
	color: white;
	text-decoration: none;
	font-size: 1.25rem;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.auth-left .auth-title {
	margin-top: 1rem;
}

.auth-left .auth-logo {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 150px;
	width: 150px;

	background-color: white;
	border-radius: 100%;
}

.auth-left .auth-logo > img {
	width: 85%;
	height: 85%;
}

/* RIGHT SIDE */
.auth-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 67%;
	height: 100vh;
}

.auth-form-container {
	text-align: left;
	width: 50%;
}

.auth-form-container > h1,
h2,
p {
	margin: 0;
}

.form {
	margin: 2rem 0rem;
}

.auth-input {
	border-radius: 1rem;
	border: 1px solid gray;
	padding: 1.25rem;
	width: 100%;
	box-shadow: none;
	margin: 0.5rem 0rem 1.5rem 0rem;
}

.auth-btn {
	border-radius: 1rem;
	border: 1px solid gray;
	margin: 0.5rem 0rem 1.5rem 0rem;
	padding: 1.25rem;
	background-color: var(--main-color-dark);
	color: white;
	width: 100%;
	transition: 0.3s;
	font-size: 1rem;
	cursor: pointer;
}

.auth-btn:hover {
	background-color: var(--main-color-light);
	color: black;
}

.message-container {
	background-color: var(--main-color-dark);
	color: white;
	padding: 0.75rem 1rem;
	border-radius: 1rem;

	position: absolute;
	right: 2%;
	top: 2%;
}

/* | PROFILE */
.profile {
	background-color: white;

	border-radius: 0.5rem;
	padding: 2rem;

	box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}

/* Style the tab */
.react-tabs__tab {
	border: none !important;
	cursor: pointer !important;
}

.react-tabs__tab:hover {
	background-color: #ddc8d0 !important;
	color: var(--main-color-dark) !important;
	transition: 0.3s !important;
}

.react-tabs__tab--selected {
	background-color: var(--main-color-dark) !important;
	color: white !important;
}

.react-tabs__tab:focus {
	outline: none !important;
}

.react-tabs__tab:focus:after {
	content: none !important;
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	.auth-left {
		display: none;
	}

	.auth-right {
		width: 100%;
		height: 100vh;
	}

	.auth-form-container {
		text-align: left;
		width: 80%;
	}

	.profile {
		width: 60%;
	}

	.profile-img {
		height: 70%;
	}
}
/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
