#inspiration-cards {
	min-height: 100vh;
}

#input-section > h2,
#design-challenge-section > h2 {
	margin-bottom: 1rem;
	color: var(--main-color-dark);
}

#design-challenge-section > p {
	margin-bottom: 1rem;
	color: black;
}

.inspiration-container {
	background-color: transparent;
	color: #fff;
	padding: 0.75rem 2rem;
	margin: 0.5rem 1rem;
	border-radius: 0.5rem;
}

.btn-group {
	display: flex;
	gap: 0.5rem;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}

/* DESIGN CHALLENGE */
.dc-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
}

/* INSPIRATION CARD */
.card-list {
	display: flex;
	row-gap: 2rem;
	column-gap: 1rem;
	flex-wrap: wrap;
	justify-content: space-between;
}

.card-title {
	color: var(--main-color-dark);
}

.card-text p {
	line-height: 2rem;
	font-size: 0.9rem;
}

.card-text {
	background-color: #fff;
	color: black;
	border-radius: 0.5rem;
	padding: 1rem;
	min-width: 256px;
	max-width: 256px;
	box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.3);
}

.card-img {
	border-radius: 0.5rem;
	height: 256px;
	width: 256px;
	min-height: 256px;
	min-width: 256px;
	margin-bottom: 0.75rem;
	object-fit: cover;
	border: 2px solid #fff;
	box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.3);
}

/* Mobile-first styles */
@media screen and (min-width: 0px) and (max-width: 480px) {
	.inspirations-container {
		justify-content: center;
	}

	.btn-group {
		display: flex;
		justify-content: space-between;
		gap: 0.5rem;
	}
}

/* Tablet styles */
@media screen and (min-width: 481px) and (max-width: 768px) {
	.inspirations-container {
		justify-content: center;
	}
}

/* Desktop styles */
@media screen and (min-width: 769px) and (max-width: 1024px) {
	/* CSS rules specific to this breakpoint */
}

/* Large desktop styles */
@media screen and (min-width: 1025px) {
	/* CSS rules specific to this breakpoint */
}
