/* Apps list */
.app-container {
	display: flex;
	gap: 2rem;
	justify-content: center;
	align-items: center;

	height: calc(100vh - 82px);
}

.app-card {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	height: 45%;
	width: 25%;
	padding: 1.5em;

	border-radius: 0.75rem;
	box-shadow: 0px 0px 15px -10px #000000;
	background-color: white;

	text-decoration: none;
	color: var(--main-color-dark);
	transition: 0.3s;

	cursor: pointer;
}

.app-card:hover {
	background-color: var(--main-color-light);
}

.app-icon {
	font-size: 7rem;
	margin-bottom: 1.5rem;
}

.hero-section {
	width: 80%;

	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 3rem;
}

.hero-text > :not(:last-child) {
	margin-bottom: 1rem;
}

.hero-img {
	width: 50%;
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	.app-card {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;

		text-align: center;

		height: 50%;
		width: 40%;
		padding: 1rem;
	}

	.hero-section {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		text-align: center;
	}

	.hero-img {
		width: 100%;
	}

	.hero-text > h1 {
		font-size: 1.5rem;
	}
}

/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
	.app-card {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;

		text-align: center;

		height: 50%;
		width: 40%;
		padding: 1rem;
	}

	.hero-section {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		text-align: center;
	}

	.hero-img {
		width: 80%;
	}

	.hero-text > h1 {
		font-size: 1.5rem;
	}
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
