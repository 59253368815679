/* Modal */
.modal {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
}

.modal-content {
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	width: 40%;
	max-height: 80vh;
	overflow-y: auto;
}

.modal-title {
	margin-bottom: 0.75rem;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	font-size: 30px;
}

.modal-content > button {
	margin-top: 1.5rem;
}

.copy-feedback {
	margin-left: 1rem;
}
