#points-of-view {
	display: flex;
	flex-direction: column;

	padding: 0;
	width: 100%;
}

#points-of-view > div {
	padding-left: 5rem;
	margin-bottom: 2rem;
}

#points-of-view > div > h2 {
	margin-bottom: 0.75rem;
	color: var(--main-color-dark);
}

/* User Cards Container */
.user-cards-container {
	display: flex;
	flex-wrap: wrap;
	column-gap: 1.5rem;
	justify-content: center;
}

/* User Card */
.user-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	background-color: var(--bg-color);
	border-radius: 0.75rem;
	box-shadow: 0px 0px 15px -10px #000000;
	padding: 0.75em;
	margin-top: 1rem;
	margin-bottom: 1rem;
	width: 28%;
	max-width: calc(33.33%);
}

.user-card-selected {
	background-color: var(--main-color-light);
}

.user-need,
.user-insight {
	font-size: 0.9rem;
}

/* User Card Top Section */
.user-card-top {
	text-align: center;
}

.user-avatar {
	width: 100%;
	max-width: 200px;
	height: auto;
	max-height: 200px;
	margin: 0 auto;
	border-radius: 0.5rem;
	object-fit: cover;
}

.user-name {
	margin: 0.5rem;
}

/* User Card Bottom Section */
.user-card-bottom {
	font-size: 0.85rem;
}

.user-info {
	margin: 0.85rem 0;
}

.user-card-action {
	text-align: center;

	color: white;
	background-color: var(--main-color-dark);
	border-radius: 1rem;
	border: 1px solid var(--main-color-dark);
	padding: 0.5rem 0.75rem;

	cursor: pointer;
	transition: 0.3s;
}

.user-card-action:hover {
	color: var(--main-color-dark);
	background-color: white;
}

.user-card-action-disabled {
	background-color: red;
}

.user-card-action-disabled {
	background-color: #ddd;
	color: #aaa;
	cursor: not-allowed;
}

/* | RESPONSIVENESS */
/* Extra Small Devices (phones) */
@media (max-width: 575px) {
	#points-of-view > div {
		padding-left: 0rem;
		margin-bottom: 0rem;
	}

	.user-card {
		width: 100%;
		max-width: 100%;
	}
}
/* Small Devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
}

/* Medium Devices (landscape tablets and small desktops) */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
	/* Your CSS rules for large screens */
}

/* Extra Large Devices (large desktops and beyond) */
@media (min-width: 1200px) {
	/* Your CSS rules for extra large screens */
}
