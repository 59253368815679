.control-btn {
	border: none;
	color: var(--main-color-dark);
	background-color: transparent;
	font-size: 1rem;
	cursor: pointer;
	outline: none;

	transition: 0.3s;
}

.control-btn:focus {
	outline: none;
}

.control-btn:disabled {
	color: gray;
	cursor: not-allowed;
}
