.input-item {
	display: flex;
	align-items: center;
	gap: 2rem;
	width: 100%;
}

.input-item > div {
	width: 100%;
	margin-bottom: 1.15rem;
}

.input-field {
	border: 1px solid var(--main-color-dark);
	border-radius: 1rem;
	padding: 0.6rem;
	margin-top: 0.25rem;
	width: 100%;
}

.input-field:focus {
	border: 1px solid var(--main-color-dark);
	background-color: var(--main-color-light);
	color: black;
	box-shadow: none;
	outline: none;
}

.input-icon {
	font-size: 2rem;
	width: 50px;
	color: var(--main-color-dark);
}
